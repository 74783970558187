import { Subscription, DataUsage } from '../../models';

export interface SubscriptionState {
  dataUsage: DataUsage[];
  subscriptions: Subscription[];
}

export const initialState: SubscriptionState = {
  dataUsage: [],
  subscriptions: [],
};
